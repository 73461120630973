import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'

const Heading = styled.h1`
  color: #fff;
  font-family: 'Muli', Arial, Helvetica, sans-serif;
  @media (max-width: 768px) {
    margin-top: 30px;
  }
`
const FormWrapper = styled.div`
  text-align: center;
  width: 100%;
  border-radius: 10px;
  display: block;
  font-family: 'Muli', Arial, Helvetica, sans-serif;
  background: rgba(0, 0, 0, 0.1);
  padding: 50px;
  div {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr;
    margin: 20px;
    align-items: center;
  }
  div.form-footer {
    grid-template-columns: 1fr;
    justify-items: center;
  }
  label {
    text-align: left;
    text-transform: uppercase;
  }
  input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    background: #f2f2f2;
    transition: all 0.3s ease-in-out;
    outline: none;
  }
  input:focus {
    background: #fff;
    border: 2px #fdd760;
    box-shadow: 0 0 5px #fdd760;
    border: 1px solid #fdd760;
  }
  button {
    display: inline-block;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    background: rgba(0, 0, 0, 0.1);
    border: 2px solid rgba(255, 255, 255, 1);
    padding: 20px 80px;
    color: #fff;
    font-family: 'Muli', Arial, Helvetica, sans-serif;
    font-size: 25px;
    text-transform: uppercase;
    text-decoration: none;
    &:hover {
      background: #fff;
      color: #0f805f;
      cursor: pointer;
      &:after {
        color: #0f805f;
      }
    }
    &:after {
      content: ' →';
      color: rgba(255, 255, 255, 0.7);
    }
  }

  @media (max-width: 700px) {
    margin: 0;
    p {
      margin: 0;
    }
    div {
      grid-template-columns: 1fr;
      text-align: left;
      grid-gap: 5px;
      margin: 20px 0;
    }
    label {
      text-align: left;
      font-weight: 600;
    }
    button {
      width: 100%;
    }
  }
  @media (max-width: 580px) {
    border-radius: 0;
    padding: 20px;
    button {
      padding: 20px 80px;
    }
  }
`

const TalkToUs = () => (
  <Layout>
    <Heading>Talk to Us</Heading>
    <FormWrapper>
      <p>
        <span role="img" aria-label="Hi">
          👋
        </span>{' '}
        Hi, We would love to hear from you.
        <br /> Complete the form below and we'll get back to you ASAP.
      </p>
      <form
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        action="/success"
      >
        <input type="hidden" name="form-name" value="contact" />

        <div>
          <label>Name</label>
          <input type="text" name="Name" placeholder="Enter your full name" />
        </div>
        <div>
          <label>Company</label>
          <input
            type="text"
            name="Company Name"
            placeholder="Enter your company name"
          />
        </div>
        <div>
          <label>Contact Number</label>
          <input
            type="tel"
            name="Contact Number"
            placeholder="(000) 000-0000"
          />
        </div>
        <div>
          <label>Email</label>
          <input type="email" name="Email" placeholder="name@surname.com" />
        </div>
        <div className="form-footer">
          <button type="submit">SEND</button>
        </div>
      </form>
    </FormWrapper>
  </Layout>
)

export default TalkToUs
