import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import bg from '../images/bg.jpg'
import logo from '../images/crew-tracker-logo.svg'
import './layout.css'

const Wrapper = styled.div`
  background: #333;
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`
const LogoWrapper = styled.div`
  background: url(${bg}) no-repeat left top;
  background-size: cover;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  padding: 50px;
`
const ContentWrapper = styled.div`
  background: #0f805f;
  color: #fff;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 100px;
  align-items: end;
  justify-items: center;
  div {
    text-align: center;
  }
  .content-area {
    align-self:center;
  }
  .copyright {
    align-self: center;
    font-family: 'Muli', Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    a {
      color: rgba(255, 255, 255, 0.7);
      text-decoration: none;
      &:hover {
        color: rgba(255, 255, 255, 1);
      }
    }
  }
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content: 'CrewTracker - Tracking your employees made easy.',
            },
            { name: 'keywords', content: 'employee management, time tracking' },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <Wrapper>
          <LogoWrapper>
            <Link to="/">
              <img src={logo} alt="CrewTracker" />
            </Link>
          </LogoWrapper>
          <ContentWrapper>
            <div className="content-area">{children}</div>
            <div className="copyright">
              &copy; CrewTracker is a product of{' '}
              <a
                href="https://bigbrave.digital"
                target="_blank"
                rel="noopener noreferrer"
              >
                BIGBrave&reg;
              </a>
            </div>
          </ContentWrapper>
        </Wrapper>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
